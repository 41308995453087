<app-header></app-header>

<ng-container *ngIf="slider">
	<section class="home-slider">
		<div *ngIf="size > 767; else mobileTemplate">
			<owl-carousel [options]="CarouselOptionsHome" [items]="slider" [carouselClasses]="['home-slider']">
				<ng-container *ngFor="let slider_item of slider">
					<a href="{{slider_item.url}}">
						<div *ngIf="slider_item" class="item" [ngStyle]="{'background-image':' url(' + slider_item?.image_desktop + ')'}">
							<div class="item-content">
								<div class="container">
									<ng-container *ngIf="slider_item.button_title != ''">
										<h3>{{slider_item.button_title}}</h3>
									</ng-container>
									<h2>{{slider_item.title}}</h2>
									<p>{{slider_item.label}}</p>
								</div>
							</div>
						</div>
					</a>		
				</ng-container>
			</owl-carousel>
		</div>
		<ng-template #mobileTemplate>
			<owl-carousel [options]="CarouselOptionsHome" [items]="slider" [carouselClasses]="['home-slider']">
				<ng-container *ngFor="let slider_item of slider">
					<a href="{{slider_item.url}}">
						<div *ngIf="slider_item" class="item" [ngStyle]="{'background-image':' url(' + slider_item?.image_mobile + ')'}">
							<div class="item-content">
								<div class="container">
									<ng-container *ngIf="slider_item.button_title != ''">
										<h3>{{slider_item.button_title}}</h3>
									</ng-container>
									<h2>{{slider_item.title}}</h2>
									<p>{{slider_item.label}}</p>
								</div>
							</div>
						</div>	
					</a>	
				</ng-container>
			</owl-carousel>	
		</ng-template>
	</section>
</ng-container>

<div *ngIf="shedule">
	<section class="home-tv">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-4">
					<div class="home-tv-start" *ngFor="let shedule_item of shedule; first as isFirst">
						<div *ngIf="isFirst">
							<a href="{{ urlLanguage }}/live" class="home-tv-logo"><img src="assets/img/logo_inter_blue.svg"></a>
							<a href="{{ urlLanguage }}/live" class="home-tv-current">
								<div class="row px-2">
									<div class="col-3 col-lg-4 px-2 text-center">
										<p>{{shedule_item.date}}</p>
										<img src="assets/img/play_white.svg">
									</div>
									<div class="col-9 col-lg-8 px-2">
										<span translate>Watch online</span>
										<p>{{shedule_item.title}}</p>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-8">
					<div class="home-tv-items">
						<ng-container *ngFor="let shedule_item of shedule; let i = index">
							<div class="home-tv-item d-flex" *ngIf="i > 0">
								<a href="{{ urlLanguage }}/tv" class="home-tv-item-1">{{shedule_item.date}}</a>
								<a href="{{ urlLanguage }}/tv" class="home-tv-item-2">{{shedule_item.title}}</a>
							</div>
						</ng-container>
						<a href="{{ urlLanguage }}/tv" class="home-tv-item" *ngIf="shedule">
							<p class="d-none d-lg-block">&nbsp;</p>
							<p class=""><span translate>Full schedule</span> <img src="assets/img/arrow_right.svg"></p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>

<div class="clear_30"></div>

<div class="video-card-player live">
	<iframe width="100%" height="400" frameborder="0" src="https://www.youtube.com/embed/emvZnAd4Qcw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>	
</div>

<div class="clear_30"></div>
<div class="clear_30"></div>

<!--
<div *ngIf="anons">
	<section class="section-widget">
		<div class="container">
			<h2 translate>Coming soon to Inter</h2>
			<div class="row">
				<div class="col-12 col-md-4" *ngFor="let anons_item of anons">
					<div *ngIf="anons_item.img">
						<a href="{{anons_item.url}}" class="animated-image">
							<img src="{{anons_item.img}}" class="w-100">
						</a>
					</div>
					<a href="{{anons_item.url}}" class="section-widget-title">{{anons_item.title}}</a>
					<p class="section-widget-meta">{{anons_item.date}}</p>
					<p class="section-widget-meta" *ngIf="anons_item.project_name"><span translate>Channel</span>: <a href="{{anons_item.project_url}}">{{anons_item.project_name}}</a></p>
					<div class="clear_15 d-lg-none"></div>
				</div>		
			</div>
			<div class="text-center">
				<a href="{{ urlLanguage }}/playlist/anonsy" class="section-widget-btn" translate>All announcements</a>
			</div>
		</div>
	</section>
	<div class="clear_60"></div>
</div>
-->

<div *ngIf="video_items">
	<section class="section-widget section-widget-video">
		<div class="container">
			<h2 translate>Video</h2>
			<div class="row">
				<div class="col-12 col-md-9">
					<div class="row">
						<div class="col-12 col-md-4" *ngFor="let video of video_items">
							<div *ngIf="video.img">
								<a href="{{video.url}}" class="animated-image">
									<img src="{{video.img}}" class="w-100">
								</a>
							</div>
							<a href="{{video.url}}" class="section-widget-title">{{video.title}}</a>
							<p class="section-widget-meta">{{video.date}}</p>
							<p class="section-widget-meta" *ngIf="video.pl_name"><span translate>Channel</span>: <a href="{{video.pl_url}}">{{video.pl_name}}</a></p>
							<div class="clear_15"></div>
						</div>	
					</div>	
					<div class="text-center">
						<a href="{{ urlLanguage }}/video" class="section-widget-btn" translate>More Videos</a>
					</div>				
				</div>
				<div class="col-12 col-md-3 d-none d-md-flex">
					<div *ngIf="sidebar_ads_1_img; else adsPremium1Template">
						<div class="clear_20 d-lg-none"></div>
						<a href="{{sidebar_ads_1_url}}">
							<img src="{{sidebar_ads_1_img}}" class="w-100">
						</a>
					</div>
					<ng-template #adsPremium1Template>
						<div class="clear_20 d-lg-none"></div>
						<div id="div-gpt-ad-1516984738506-3"></div>
					</ng-template>
				</div>			
			</div>
		</div>
	</section>
	<div class="clear_60"></div>
</div>

<div *ngIf="horizontal_ads_1_img; else adsHorizontal1Template">
	<section>
		<a href="{{horizontal_ads_1_url}}" target="_blank" rel="nofollow">
			<img src="{{horizontal_ads_1_img}}" class="w-100">
		</a>
		<div *ngIf="horizontal_code_1">
			<div [innerHTML]="horizontal_code_1"></div>
		</div>		
	</section>
	<div class="clear_60"></div>
</div>
<ng-template #adsHorizontal1Template>
	<div id="div-gpt-ad-1516984738506-5"></div>
	<div class="clear_60"></div>
</ng-template>

<div *ngIf="tv">
	<section class="section-widget">
		<div class="container">
			<h2 translate>TV Projects</h2>
			<div class="row p-0 m-0">
				<a *ngFor="let tv_item of tv" href="{{tv_item.url}}" class="col-12 col-md-4 p-0 m-0 animated-image" title="{{tv_item.title}}"><img src="{{tv_item.img}}" alt="{{tv_item.title}}" class="w-100"></a>	
			</div>	
			<div class="text-center">
				<a href="{{ urlLanguage }}/programs" class="section-widget-btn" translate>All TV projects</a>
			</div>
		</div>
	</section>
	<div class="clear_60"></div>
</div>

<div *ngIf="horizontal_ads_2_img; else adsHorizontal2Template">
	<section>
		<a href="{{horizontal_ads_2_url}}" target="_blank" rel="nofollow">
			<img src="{{horizontal_ads_2_img}}" class="w-100">
		</a>
		<div *ngIf="horizontal_code_2">
			<div [innerHTML]="horizontal_code_2"></div>
		</div>			
	</section>
	<div class="clear_60"></div>
</div>
<ng-template #adsHorizontal2Template>
	<div id="div-gpt-ad-1516984738506-1"></div>
	<div class="clear_60"></div>
</ng-template>

<div *ngIf="home_news">
	<section class="section-widget">
		<div class="container">
			<h2 translate>News</h2>
			<div class="row">
				<div class="col-12 col-md-4" *ngFor="let story of home_news">
					<div *ngIf="story.img">
						<a href="{{story.url}}" class="animated-image">
							<img src="{{story.img}}" class="w-100">
						</a>
						<div class="clear_15"></div>
					</div>
					<div class="article-item-meta-box">
						<a href="{{story.category_url}}" class="tag-item left active">{{story.category}}</a>
						<p class="section-widget-meta">{{story.date}}</p>						
					</div>	
					<a href="{{story.url}}" class="section-widget-title">{{story.title}}</a>
					<div class="clear_15"></div>
				</div>		
			</div>	
			<div class="text-center">
				<a href="{{ urlLanguage }}/news" class="section-widget-btn" translate>More News</a>
			</div>
		</div>
	</section>
	<div class="clear_60"></div>
</div>

<div *ngIf="horizontal_ads_3_img; else adsHorizontal3Template">
	<section>
		<a href="{{horizontal_ads_3_url}}" target="_blank" rel="nofollow">
			<img src="{{horizontal_ads_3_img}}" class="w-100">
		</a>
		<div *ngIf="horizontal_code_3">
			<div [innerHTML]="horizontal_code_3"></div>
		</div>			
	</section>
	<div class="clear_60"></div>
</div>
<ng-template #adsHorizontal3Template>
	<div id="div-gpt-ad-1516984738506-2"></div>
	<div class="clear_60"></div>
</ng-template>

<div *ngIf="special">
	<section class="section-widget">
		<div class="container">
			<h2 translate>Special Projects</h2>
			<owl-carousel [options]="CarouselOptions2" [items]="special" [carouselClasses]="['special-slider']" > 	
				<div class="item" *ngFor="let special_item of special">
					<a href="{{special_item.url}}" class="animated-image"><img src="{{special_item.img}}" class="w-100"></a>	
					<div class="clear_15"></div>
					<div class="text-center">
						<a href="{{special_item.url}}" target="_blank">{{special_item.title}}</a>
					</div>
				</div>		
			</owl-carousel>	
			<div class="text-center">
				<a href="{{ urlLanguage }}/special-projects" class="section-widget-btn" translate>All Slecial Projects</a>
			</div>			
		</div>
	</section>
	<div class="clear_60"></div>
</div>

<app-footer></app-footer>