<app-header></app-header>

<section>
	<div class="container py-5">
		
		<div class="row">
			<div class="col-12">
				<h1 class="article-title" translate>Inter online</h1>
			</div>		
		</div>
		
		<div class="clear_15 d-none d-md-block"></div>
		
		<div class="row">
		
			<div class="col-12 col-md-8">
				
				<div class="video-card">
					
					<div class="video-card-player live">
						<!-- <iframe *ngIf="url" width="100%" height="600" [src]="url" frameborder="0" scrolling="no" allowfullscreen></iframe>	-->
						<iframe width="100%" height="400" frameborder="0" src="https://www.youtube.com/embed/emvZnAd4Qcw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>	
					</div>
					<!--
					<div class="row">
						<div class="col-12 col-md-7">
							<div class="clear_15"></div>
							<h2>11-летний вокалист Артур Галас | Круче всех!</h2>
							<p class="section-widget-meta">24 июля 2019 | 15:00</p>
							<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{ urlLanguage }}/video">СЕРИАЛЫ</a></p>	
							<div class="video-card-description">
								<p>Встречайте: на сцене шоу "Круче всех" 11-летний вокалист из Львовской области Артур Галас, который в этом году представлял Украину на «Славянском базаре» и завоевал второе место в детском конкурсе. У этого талантливого мальчика – непростая задача: помочь ведущим спеть арию из «Нотр-Дам де Пари». Мало того, что слова будут далеки от оригинального текста, так еще и Андрей Данилевич – практически не поющий ведущий. Каким будет новое трио в мире мюзиклов – Артур Галас, Андрей Доманский и Андрей Данилевич?</p>
								<p>КОМАНДА ПРОГРАММЫ ПРОДОЛЖАЕТ ИСКАТЬ ТАЛАНТЛИВЫХ ДЕТЕЙ! Если ваш ребенок добился успехов в своем увлечении или у него необычное хобби, расскажите о нем, написав по адресу: kv@inter.ua. Быть может, именно ваш малыш в одном из следующих выпусков докажет всей Украине: он – Круче всех!</p>
								<div class="clear_15"></div>
								<div class="row">
									<div class="col-12 col-md-6">
										<h3 translate>See</h3>
										<div class="after-live-item">
											<span>15:15</span> по понедельникам
										</div>
									</div>
									<div class="col-12 col-md-6">
										<h3 translate>The nearest broadcast</h3>
										<div class="after-live-item">
											<span>15:15</span> вторник, 17 сентября
										</div>
										<div class="after-live-item">
											<span>15:15</span> среда, 18 сентября 
										</div>										
									</div>									
								</div>
							</div>						
						</div>
						<div class="col-12 col-md-5">
							<div class="bg-playlist">
								<h3 translate>Now live</h3>
								<div class="now-item">
									<div class="row px-2">
										<div class="col-4 px-2">
											<img src="assets/img/v_1.png" class="w-100">
										</div>
										<div class="col-8 px-2">
											<h3 class="m-0">15:10</h3>
											<div class="clear_5"></div>
											<a href="{{ urlLanguage }}/video/cxcddsd">11-летний вокалист Артур Галас | Круче всех!</a>
										</div>									
									</div>
								</div>
								<h3 translate>Next</h3>
								<div class="now-item">
									<div class="row px-2">
										<div class="col-4 px-2">
											<img src="assets/img/v_2.png" class="w-100">
										</div>
										<div class="col-8 px-2">
											<h3 class="m-0">18:00</h3>
											<div class="clear_5"></div>
											<a href="{{ urlLanguage }}/video/cxcddsd">6-летняя танцовщица соло Агата Бузыкина | Круче всех!</a>
										</div>									
									</div>
								</div>		
								<div class="now-item">
									<div class="row px-2">
										<div class="col-4 px-2">
											<img src="assets/img/v_3.png" class="w-100">
										</div>
										<div class="col-8 px-2">
											<h3 class="m-0">19:00</h3>
											<div class="clear_5"></div>
											<a href="{{ urlLanguage }}/video/cxcddsd">Абсолютный чемпион Украины по прыжкам на акробатической дорожке Иван Пузанов | Круче...</a>
										</div>									
									</div>
								</div>	
								<div class="now-item">
									<div class="row px-2">
										<div class="col-4 px-2">
											<img src="assets/img/v_1.png" class="w-100">
										</div>
										<div class="col-8 px-2">
											<h3 class="m-0">20:00</h3>
											<div class="clear_5"></div>
											<a href="{{ urlLanguage }}/video/cxcddsd">13-летний обладатель Гран-при по спортивному жонглированию Богдан Самаров | Круче всех!</a>
										</div>									
									</div>
								</div>	
								<div class="now-item">
									<div class="row px-2">
										<div class="col-4 px-2">
											<img src="assets/img/v_2.png" class="w-100">
										</div>
										<div class="col-8 px-2">
											<h3 class="m-0">21:05</h3>
											<div class="clear_5"></div>
											<a href="{{ urlLanguage }}/video/cxcddsd">11-летняя дизайнер Таисия Старостюк | Круче всех!</a>
										</div>									
									</div>
								</div>	
							</div>
						</div>						
					</div>
					-->
					<div *ngIf="content">	
						<div class="clear_40"></div>
						<div id="project-about">
							<div class="project-about-description" [innerHTML]="content"></div>
						</div>					
					</div>					
				</div>

				<div class="clear_40"></div>
				
				<div *ngIf="playlists">
					<div id="project-playlists">
						<div *ngFor="let playlist of playlists">
							<div *ngIf="playlist">
								<div class="horizontal-widget">
									<h2 class="horizontal-widget-title">
										{{playlist.title}} <span class="horizontal-widget-more d-none d-md-inline">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</h2>
									<owl-carousel [options]="CarouselOptions2"  [carouselClasses]="['standart_carousel_four']" >  
										<div class="item" *ngFor="let pl_item of playlist.items">  
											<a href="{{pl_item.url}}" class="animated-image">
												<img src="{{pl_item.img}}" class="w-100">
											</a>
											<a href="{{pl_item.url}}" class="section-widget-title">{{pl_item.title}}</a>
											<p class="section-widget-meta">{{pl_item.date}}</p>
											<p class="section-widget-meta"><span translate>Channel</span>: <a href="{{pl_item.project_url}}">{{pl_item.project_name}}</a></p>
											<div class="clear_15 d-lg-none"></div> 
										</div>  
									</owl-carousel>							
									<div class="text-center d-md-none">
										<span class="horizontal-widget-more">&nbsp;&nbsp;<a href="{{playlist.url}}"><span translate>Play All</span> <img src="assets/img/gray_arrow_right.svg"></a></span>
									</div>														
								</div>
								<div class="clear_40"></div>
							</div>
						</div>	
					</div>
					<div class="clear_40"></div>
				</div>
				
			</div>
			
			<div class="col-12 col-md-4">			
				<app-sidebar></app-sidebar>					
			</div>			
		</div>
	</div>
</section>

<app-footer></app-footer>